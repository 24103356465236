import "./section-4.css";
import React from "react";
import { BiUserCircle } from "react-icons/bi";
import { GoLocation } from "react-icons/go";
import { AiOutlineBarChart, AiOutlineDollar } from "react-icons/ai";
import { BiTime } from "react-icons/bi";
import { BsBarChartLine } from "react-icons/bs";
// import { FiFileText } from "react-icons/fi";

// import PriceCard from "../../general/price-card/price-card.js";

export class PSection4 extends React.Component {

  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  handleButtonClick = (value) => {
    localStorage.removeItem('selectedPlan_v2');
    localStorage.setItem('selectedPlan_v2', value);
    this.divRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    return (
      <><div className="bg-black">
        <div id="partner-section-4">
          <h2 className="text-white">Introductory offer to get you started with BarM8</h2>
          <br />


          <div className="container-xs container-sm container-md align-self-center mx-auto">
            <div className="row mt-4">
              <div className="col-md-4 h-100 d-inline-block">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="price-card">
                      <div className="h-100 d-inline-block">
                        <div className="row">
                          {/* <div className="col-sm-12">
                          <span class="m-p-bg-color">Most popular</span>
                        </div> */}

                          <div className="col-sm-12">
                            <div className="mh-100 mx-h-full">
                              <h4 className="text-white">Basic</h4>
                              <p className="text-white p-d-f-1-b">
                                Gives your venue a fighting chance with a
                                listing on BarM8 App
                              </p>
                              {/* <p className="text-gray-300 p-m-sp">
                                <span className="fs-4 text-white">$0</span>
                                <span className="text-gray-300 p-f-p-c">
                                  &nbsp;&nbsp;/ month
                                </span>
                              </p>
                              <p className="p-m-sp">
                                <span className="text-gray-300 p-f-p-c">
                                  Usually $49 / month
                                </span>
                              </p> */}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button onClick={this.handleButtonClick.bind(this, 'Basic')} className="btn btn-c-g-s-g btn-block">
                              Get started
                            </button>
                          </div>
                          {/* <div className="col-sm-12">
                          <div className="s-3-p-sec">
                            <h6 className="text-uppercase text-white mt-5">
                              payments
                            </h6>
                            <p className="text-white payment-t-s p-m-sp mt-3">
                              Transaction fees for all payment providers 2.0%
                            </p>
                            <p className="text-white payment-t-s p-m-sp mt-2">
                              Currency conversion fee 2.0%
                            </p>
                          </div>
                        </div> */}
                          <div className="col-sm-12">
                            <div className="s-3-f-sec">
                              <h6 className="text-uppercase text-white mt-5">
                                FEATURES
                              </h6>
                              <p className="text-white p-m-sp mt-3">
                                <BiUserCircle className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;3 profile pictures
                                </span>
                              </p>
                              <p className="text-white p-m-sp mt-2">
                                <BiTime className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;Opening and Closing times
                                </span>
                              </p>
                              <p className="text-white p-m-sp mt-2">
                                <GoLocation className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;Location map
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 h-100 d-inline-block">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="price-card">
                      <div className="h-100 d-inline-block">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="mh-100 mx-h-full">
                              <h4 className="text-white">Intermediate</h4>
                              <p className="text-white p-d-f-1-i">
                                Lets you showcase What's On at your venue.
                                List all the deals - Happy Hour, Special Offers
                                Events - DJ, Live Music, Comedy, Trivia and more.
                              </p>
                              {/* <p className="text-gray-300 p-m-sp">
                                <span className="fs-4 text-white">$49</span>
                                <span className="text-gray-300 p-f-p-c">
                                  &nbsp;&nbsp;/ month
                                </span>
                              </p>
                              <p className="p-m-sp">
                                <span className="text-gray-300 p-f-p-c">
                                  Usually $99 / month
                                </span>
                              </p> */}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button onClick={this.handleButtonClick.bind(this, 'Intermediate')} className="btn btn-c-g-s-g btn-block">
                              Get started
                            </button>
                          </div>
                          {/* <div className="col-sm-12">
                          <div className="s-3-p-sec">
                            <h6 className="text-uppercase text-white mt-5">
                              payments
                            </h6>
                            <p className="text-white payment-t-s p-m-sp mt-3">
                              Transaction fees for all payment providers 1.0%
                            </p>
                            <p className="text-white payment-t-s p-m-sp mt-2">
                              Currency conversion fee 2.0%
                            </p>
                          </div>
                        </div> */}
                          <div className="col-sm-12">
                            <div className="s-3-f-sec">
                              <h6 className="text-uppercase text-white mt-5">
                                FEATURES
                              </h6>

                              <p className="text-white p-m-sp mt-3">
                                <BiUserCircle className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;Basic with 5 Profile pictures
                                </span>
                              </p>
                              <p className="text-white p-m-sp mt-2">
                                <GoLocation className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;Unlimited Deals - Happy Hour, Special Offers
                                </span>
                              </p>
                              <p className="text-white p-m-sp mt-2">
                                <AiOutlineBarChart className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;Unlimited Events - DJ, Live Music, Comedy, Trivia and more
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 h-80 d-inline-block">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="price-card">
                      <div className="h-100 d-inline-block">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="mh-100 mx-h-full">
                              <h4 className="text-white">Advanced</h4>
                              <p className="text-white">
                                Get the best of BarM8 with advanced
                                features and best ROI. Showcase What's On
                                at your venue.

                              </p>
                              <p className="text-white"> Get full access to real-time Customer Data
                                with Analytics.</p>
                              {/* <p className="text-gray-300 p-m-sp">
                              Starting at
                            </p> */}
                              {/* <p className="text-gray-300 p-m-sp">
                                <span className="fs-4 text-white">$99</span>
                                <span className="text-gray-300 p-f-p-c">
                                  &nbsp;&nbsp;/ month
                                </span>
                              </p>
                              <p className="p-m-sp">
                                <span className="text-gray-300 p-f-p-c">
                                  Usually $149 / month
                                </span>
                              </p> */}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button onClick={this.handleButtonClick.bind(this, 'Advanced')} className="btn 
                            btn-c-g-s-g btn-block">
                              Get started
                            </button>
                          </div>
                          {/* <div className="col-sm-12">
                          <div className="s-3-p-sec">
                            <h6 className="text-uppercase text-white mt-5">
                              payments
                            </h6>
                            <p className="text-white payment-t-s p-m-sp mt-3">
                              Transaction fees for all payment providers 0.5%
                            </p>
                            <p className="text-white payment-t-s p-m-sp mt-2">
                              Currency conversion fee 2.0%
                            </p>
                            <p className="text-white payment-t-s p-m-sp mt-2">
                              Duties and import taxes rates 0.85%
                            </p>
                            <p className="text-gray-300 payment-t-s-extra p-m-sp mt-1">
                              If you are using payment providers other than
                              Barm8 Payments: 1.5%
                            </p>
                          </div>
                        </div> */}
                          <div className="col-sm-12">
                            <div className="s-3-f-sec">
                              <h6 className="text-uppercase text-white mt-5">
                                FEATURES
                              </h6>
                              <p className="text-white p-m-sp mt-3">
                                <BiUserCircle className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;Intermediate with video profile
                                </span>
                              </p>
                              <p className="text-white p-m-sp mt-2">
                                <GoLocation className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;List Exclusive Deals
                                </span>
                              </p>
                              <p className="text-white p-m-sp mt-2">
                                <AiOutlineBarChart className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;List Featured Events
                                </span>
                              </p>
                              <p className="text-white payment-t-s p-m-sp mt-2">
                                <BsBarChartLine className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;Patron Data capture with Beacon Technology
                                </span>
                              </p>
                              <p className="text-white payment-t-s p-m-sp mt-2">
                                <AiOutlineDollar className="i-f-s" />
                                <span className="features-t-s">
                                  &nbsp;&nbsp;Complete access to Data and Analytics
                                </span>
                              </p>
                              {/* <p className="text-white payment-t-s p-m-sp mt-2">
                              <FiFileText className="i-f-s" />
                              <span className="features-t-s">
                                &nbsp;&nbsp;Duties and import taxes
                              </span>
                            </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div ref={this.divRef}></div>
      </>
    );
  }
}

export default PSection4;
