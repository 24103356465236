import "./section-1.css";
import Phone from "../phone/phone.js";
import React, { Component } from "react";

export default class Section1 extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "./scripts.js";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <div id="section-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="left" id="left">
                <h1>...we're for good times.</h1>
                <h4>
                  BarM8 makes discovering new bars, and all the great stuff they
                  have going on, easy. Free with no subscription, it's the smart
                  way to socialise in Sydney.
                </h4>
              </div>
              <div className="app-link-css">
                <div className="button-group">
                  <div className="row">
                    <div className="col-12">
                      <div className="center-i">
                        <a href="https://web.barm8.com.au/"
                          className="disc-now-btn">Discover your city now</a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="mt-10">
                        <h2 className="gt-app-txt">Get the BarM8 app</h2>
                        <p className="gt-app-txt-1">Scan this QR code to download the app now</p>
                      </div>
                    </div>
                    <div className="col-12" >
                      <div className="center-i">
                        <img src="https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/940ad858f3003e58155ecaa088fd67fb.svg" className="qr-code" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="center-i">
                        <p className="gt-app-txt-2">Or check it out in the app stores</p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="d-l-b-a-i mb-10">
                      <div className="col-6">
                        <a
                          href="https://apps.apple.com/au/app/barm8/id1402470106"
                          target="_blank"
                        >
                          <img
                            src="https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/_app_store.svg"
                            className="store-button m-l-ap"
                            alt="BarM8 App Store"
                          />
                        </a>
                      </div>
                      <div className="col-6">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.barm8"
                          target="_blank"
                        >
                          <img
                            src="https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/_google_play_store.svg"
                            className="store-button"
                            alt="BarM8 Play Store"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="store-wrapper">

                    </div>
                  </div>
                  <div className="col-6"></div> */}
                </div>
                {/* <div className="row">

                </div> */}
              </div>
            </div>
            <div className="col-lg-5">
              <Phone src="https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/image1-min.png" id="image1" />
            </div>
          </div>
        </div>

        <div className="styles__SideNav-kd2kl4-1 gOrZUu">
          <a className="styles__ScrollLink-kd2kl4-0 jwpVBP bullet1 active"></a>
          <a className="styles__ScrollLink-kd2kl4-0 jwpVBP bullet2"></a>
          <a className="styles__ScrollLink-kd2kl4-0 jwpVBP bullet3"></a>
          <a className="styles__ScrollLink-kd2kl4-0 jwpVBP bullet4"></a>
        </div>

        <div className="half-phone-box">
          <div className="frame">
            <div className="notch"></div>
            <div className="iphone">
              <div id="comparison">
                <figure>
                  <div id="divisor"></div>
                  <div id="divisor1"></div>
                  <div id="divisor2"></div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
