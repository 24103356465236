import "./footer.css";
import React from "react";

export class Footer extends React.Component {
  render() {
    return (
      <div id="footer">
        <div className="container">
          <div className="row">
            <div className="brand">
              <img src="logo.png" title="Barm8 Logo" alt="BarM8"></img>
            </div>
          </div>
          <div className="row mt-lg-3">
            <h1 className="download-tag" title="Download Bar M8 free today">
              Download Bar M8 free today
            </h1>
          </div>
          <div className="row mt-lg-3">
            <div className="">
              <a
                href="https://apps.apple.com/au/app/barm8/id1402470106"
                title="BarM8 App Store"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/_app_store.svg"
                  className="store-button-footer"
                  title="BarM8 App Store"
                  alt="BarM8 App Store"
                ></img>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.barm8"
                title="BarM8 play Store"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://barm8.syd1.cdn.digitaloceanspaces.com/website-files/_google_play_store.svg"
                  className="store-button-footer"
                  title="BarM8 Play Store"
                  alt="BarM8 Play Store"
                ></img>
              </a>
            </div>
          </div>
          <div className="row mt-lg-3">
            <ul class="social row">
              <li className="col">
                <div className="justify-content-end float-right">
                  <a
                    class="ease-all face-book"
                    target="_blank"
                    title="Facebook"
                    href="https://www.facebook.com/barm8app/"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </div>
              </li>
              <li className="col">
                <a
                  className="ease-all instagram"
                  href="https://www.instagram.com/barm8app/"
                  target="_blank"
                  title="Instagram"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="m-4"> </div>
          </div>
          <div className="container mt-2">
            <div className="row">
              <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                <div className="row">
                  <div className="col col-4">
                    <a
                      href="https://barm8.com.au/contact"
                      title="contact us"
                      className="text-white fst-normal"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact
                    </a>
                  </div>
                  <div className="col col-4">
                    <a
                      href="https://barm8.com.au/terms"
                      title="Terms of Service"
                      className="text-white fst-normal"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>
                  </div>
                  <div className="col col-4">
                    <a
                      href="https://barm8.com.au/privacy"
                      title="Privacy Policy"
                      className="text-white fst-normal"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col">
                <p className="text-white fst-normal" title="BarM8">
                  ©
                  <a className="text-white fs-6" href="/" rel="noreferrer">
                    BarM8™
                  </a>
                  All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
